import { formatCurrency } from '@angular/common';
import { nanoid } from 'nanoid';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BetCoupon,
  BetCouponGlobalVariable,
  BetCouponGroup,
  Bonus,
  ClientsideCouponService,
  CouponAction,
  CouponType,
  Selection,
  UpdateCouponRequest,
  UpdateCouponResponse,
} from 'clientside-coupon';
import { cloneDeep } from 'lodash-es';
import { RecentBets } from '@kingmakers-tech/mobile-virtuals-soccer';
import { LocalStorageService } from 'ngx-webstorage';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { AccumulatorBonusStore } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.store';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { VirtualsCouponStore } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { CouponGroupingType, DefaultCouponStake } from 'src/app/shared/models/coupon.model';
import { BetslipActions, DataLayerProduct } from 'src/app/shared/models/datalayer.model';
import { INSERT_COUPON_STATUS_CODES, UPDATE_VIRTUALS_COUPON_STATUS_CODES } from 'src/app/shared/utils/coupon-status-codes';
import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';
import { VirtualsScheduledFreeBetService } from 'src/app/modules/freebets/services/virtuals-scheduled-free-bet.service';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { VirtualsCouponSettings } from 'src/app/shared/models/virtuals-coupon.model';
import { Router } from '@angular/router';
import { VirtualsMyBetsService } from 'src/app/modules/my-bets/services/my-bets-virtuals.service';
import { ABTestService } from 'src/app/core/services/ab-test.service';
import { CookieService } from 'src/app/core/services/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class VirtualsCouponService implements OnDestroy {
  loading: boolean = false;
  enforceSingleCombination: boolean = false;
  private readonly couponDataKey = 'virtualsCouponData';
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly ADJUST_ID = 'AdjustIdid';
  private readonly ADJUST_GPS_ID = 'gpsAdid';
  private readonly ADJUST_FRA_ID = 'AdjustIdfa';

  constructor(
    private readonly accumulatorBonusStore: AccumulatorBonusStore,
    private readonly virtualsCouponStore: VirtualsCouponStore,
    private readonly accountQuery: AccountQuery,
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly virtualsCouponQuery: VirtualsCouponQuery,
    private readonly accountService: AccountService,
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly applicationService: ApplicationService,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly dataLayerService: DataLayerService,
    private readonly localStorage: LocalStorageService,
    private readonly virtualsMyBetsService: VirtualsMyBetsService,
    private readonly notificationService: NotificationService,
    private readonly freebetService: VirtualsScheduledFreeBetService,
    private readonly router: Router,
    private readonly virtualsStore: VirtualsStore,
    private readonly abTestService: ABTestService,
    private readonly cookieService: CookieService
  ) {
    this.virtualsCouponQuery.couponSettings$.subscribe(sub => {
      this.virtualsCouponStore.updateCouponSettings(sub);
    });
  }

  get currencySymbol(): string {
    return this.accountQuery.userData && this.accountQuery.userData.currency.symbol
      ? this.accountQuery.userData.currency.symbol
      : this.appConfig.get('virtuals').scheduledLeague.defaultCurrency;
  }

  initialize(): void {
    if (this.virtualsCouponQuery.couponInitialized) {
      // Get bonus list, because we are storing bonus list for sports/virtuals/instant at one place in the store
      this.apiService.get<any>(APIType.VirtualsFeeds, `v1/settings/bonuses`).subscribe(bonusListData => {
        if (bonusListData !== undefined) {
          const bonusList: Bonus[] = bonusListData;
          this.accumulatorBonusStore.updateBonusList(bonusList);
        }
      });
    } else {
      this.populateSessionVariables()
        .pipe(first())
        .subscribe(() => {
          this.virtualsCouponStore.updateCouponInitialized(true);
          this.removeExpiredOdds();
        });
    }

    // If logged out, reset stale time so that freebet vouchers are retrieved next time
    this.accountQuery.isAuthenticated$
      .pipe(
        filter(isAuth => !isAuth),
        tap(() => this.freebetService.resetVouchersandGetUserVouchersStaleTime()),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Set Virtuals as active Free Bet Product
    this.freebetService.setActiveFreeBetProduct(FreeBetProductType.VirtualsScheduled);

    // Get Adjust IDS and set them to Cookie
    this.retrieveAndSaveAdjustIds();
  }

  async retrieveAndSaveAdjustIds(): Promise<void> {
    try {
      const adjustIds = await this.dataLayerService.getAdjustIds();

      if (adjustIds) {
        const { adjustId, gpsAdid, idfa } = adjustIds;

        if (adjustId) {
          this.cookieService.setCookie(this.ADJUST_ID, adjustId);
        }
        if (gpsAdid) {
          this.cookieService.setCookie(this.ADJUST_GPS_ID, gpsAdid);
        }
        if (idfa) {
          this.cookieService.setCookie(this.ADJUST_FRA_ID, idfa);
        }
      }
    } catch (error) {
      console.error('Error retrieving Adjust IDs:', error);
    }
  }

  rebetCoupon(couponCode: string, language: string = 'en'): Observable<any> {
    return this.apiService.get(APIType.VirtualsBets, `v1/coupons/${couponCode}/rebet/language/${language}`).pipe(
      map(data => {
        if (data.BetCoupon) {
          data.BetCoupon.MaxPercentageBonus = isNaN(data.BetCoupon.MaxBonusPerc) ? 0 : data.BetCoupon.MaxBonusPerc;
          data.BetCoupon.MinPercentageBonus = isNaN(data.BetCoupon.MinBonusPerc) ? 0 : data.BetCoupon.MinBonusPerc;
          delete data.BetCoupon.MaxBonusPerc;
          delete data.BetCoupon.MinBonusPerc;

          const updatedCoupon = this.clientsideCouponService.formatCoupon(data.BetCoupon);
          this.virtualsCouponStore.updateCouponData(updatedCoupon);

          this.applicationService.showVirtualsCoupon();
          return data;
        } else {
          this.router.navigate(['/virtual'], {
            replaceUrl: true,
          });
          return of(false);
        }
      }),
      catchError(() => {
        this.router.navigate(['/virtual'], {
          replaceUrl: true,
        });
        return of(false);
      })
    );
  }

  addOdd(odd: Selection, language: string = 'en'): UpdateCouponResponse {
    return this.addOddClientSide(odd);
  }

  removeOdd(oddId: number): UpdateCouponResponse {
    this.virtualsCouponStore.clearGroupingTab();

    const selection = new Selection();
    selection.oddId = oddId;

    return this.updateCoupon({
      action: CouponAction.RemoveOdd,
      couponData: this.virtualsCouponQuery.couponData,
      requestData: {
        selection,
      },
    });
  }

  updateOddBankerStatus(oddId: number, isBanker: boolean): UpdateCouponResponse {
    const selection = new Selection();
    selection.oddId = oddId;

    return this.updateCoupon({
      action: CouponAction.UpdateOddBankerStatus,
      couponData: this.virtualsCouponQuery.couponData,
      requestData: {
        selection,
        isBanker,
      },
    });
  }

  clearAllBankers(): UpdateCouponResponse {
    return this.updateCoupon({
      action: CouponAction.ClearAllBankers,
      couponData: this.virtualsCouponQuery.couponData,
    });
  }

  removeOdds(oddIds: number[]): void {
    let updatedCoupon = this.virtualsCouponQuery.couponData;

    oddIds.forEach(odd => {
      const selection = new Selection();
      selection.oddId = odd;

      const response = this.updateCoupon({
        action: CouponAction.RemoveOdd,
        couponData: updatedCoupon,
        requestData: { selection },
        saveResponse: false,
      });
      updatedCoupon = response.updatedCoupon;
    });

    this.virtualsCouponStore.updateCouponData(updatedCoupon);
  }

  /** Returns the number of removed odds. */
  removeExpiredOdds(): number {
    if (!this.virtualsCouponQuery.couponData) {
      return;
    }

    const expiredOdds = this.virtualsCouponQuery.couponData.Odds.filter(odd => new Date(odd.EventDate) < new Date());
    this.removeOdds(expiredOdds.map(odd => odd.SelectionId));

    return expiredOdds.length;
  }

  setCouponData(couponData: BetCoupon): void {
    if (!couponData) {
      this.virtualsCouponStore.clearCouponData();
    } else {
      this.virtualsCouponStore.updateCouponData(couponData);
    }
  }
  updateGroupings(groupings: BetCouponGroup[]): UpdateCouponResponse {
    return this.updateCoupon({
      action: CouponAction.UpdateGroupings,
      couponData: this.virtualsCouponQuery.couponData,
      requestData: { groupings },
    });
  }

  isGroupingVisible(grouping: BetCouponGroup, isLast: boolean): boolean {
    const groupingsTabSelected = this.virtualsCouponQuery.groupingsTabSelected;

    if (groupingsTabSelected === CouponGroupingType.Split && isLast) {
      return true;
    } else if (groupingsTabSelected === CouponGroupingType.Combination) {
      if (!isLast && grouping.Grouping !== 1) {
        return true;
      }
    } else if (groupingsTabSelected === CouponGroupingType.Singles && grouping.Grouping === 1) {
      return true;
    }

    return false;
  }

  validateCoupon({ couponData, saveResponse }: { couponData: BetCoupon; saveResponse: boolean }): UpdateCouponResponse {
    return this.updateCoupon({
      action: CouponAction.ValidateCoupon,
      couponData,
      saveResponse,
    });
  }

  validateAndPostCoupon(): Observable<boolean> {
    if (this.virtualsCouponQuery.couponData === null) {
      return of(false);
    }
    const validation = this.validateCoupon({
      couponData: this.virtualsCouponQuery.couponData,
      saveResponse: false,
    });

    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      return this.postCouponLite();
    }
  }

  updateCouponSetting(couponSettingKey: string, couponSetting: any): void {
    const setting = new VirtualsCouponSettings({});
    setting[couponSettingKey] = couponSetting;
    this.virtualsCouponStore.updateCouponSetting(setting);
  }

  clearCouponData(): void {
    this.virtualsCouponStore.clearCouponData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  updateDefaultCouponStake(defaultCouponStake: DefaultCouponStake): void {
    this.virtualsCouponStore.updateDefaultCouponStake(defaultCouponStake);
  }

  getInsertCouponStatus(statusCode: number): string {
    return INSERT_COUPON_STATUS_CODES[statusCode];
  }

  getUpdateCouponStatus(statusCode: number): string {
    return UPDATE_VIRTUALS_COUPON_STATUS_CODES[statusCode];
  }

  private addOddClientSide(odd: Selection): UpdateCouponResponse {
    const response = this.updateCoupon({
      action: CouponAction.AddOdd,
      couponData: this.virtualsCouponQuery.couponData,
      requestData: {
        selection: odd,
        allowSameMatchSelections: this.appConfig.get('virtuals').scheduledLeague.allowSameMatchSelections,
      },
      saveResponse: false,
    });

    // Checking for bigger then 0 because client side coupon returns -1 when total combinations are reached
    if (response.updatedCoupon.TotalCombinations > 0) {
      this.virtualsCouponStore.updateCouponData(response.updatedCoupon);
    } else {
      this.notificationService.showErrorNotification($localize`Maximum number of combinations reached.`);
    }

    return response;
  }

  // Centralized function for calling the CSC updateCoupon function, to reduce code repetition.
  private updateCoupon({
    action,
    couponData,
    requestData,
    saveResponse = true,
  }: {
    action: CouponAction;
    couponData: BetCoupon;
    requestData?: Partial<UpdateCouponRequest>;
    saveResponse?: boolean;
  }): UpdateCouponResponse {
    const request = new UpdateCouponRequest({
      action,
      coupon: couponData,
      brandID: this.appConfig.get('brandId'),
      bonusList: this.accumulatorBonusQuery.bonusList,
      globalVariables: this.virtualsCouponQuery.globalVariables,
      marketExceptions: undefined,
      correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
      ...requestData,
    });
    const response = this.clientsideCouponService.updateCoupon(request);

    if (saveResponse) {
      this.virtualsCouponStore.updateCouponData(response.updatedCoupon);
    }
    return response;
  }

  private postCouponLite(): Observable<boolean> {
    const couponData = this.localStorage.retrieve(this.couponDataKey);
    this.loading = true;

    return this.placeCouponLite().pipe(
      map(insertResponse => {
        this.loading = false;
        const betResponseStatus = insertResponse.statusCode;
        const numberOfSelections = couponData?.Odds?.length ?? 0;
        const leagueType = couponData?.Odds?.reduce((acc: string, curr) => {
          const previous = acc.trim().toLowerCase().replace(/ /g, '-');
          const current = (curr.EventName || '').trim().toLowerCase().replace(/ /g, '-');
          return previous === current ? previous : 'mixed';
        }, couponData?.Odds?.[0]?.EventName);

        if (!insertResponse.success) {
          this.handleInsertCouponFailure(insertResponse);

          this.dataLayerService.addBetslipEventToDataLayer({
            event: BetslipActions.BetFailure,
            product: DataLayerProduct.VirtualsScheduled,
            userId: this.accountQuery.userData?.id,
            currency: this.accountQuery.userData.currency.name,
            betStake: couponData ? couponData.Stake : undefined,
            betType: couponData ? CouponType[couponData.CouponType] : undefined,
            errorMessage: this.getInsertCouponStatus(insertResponse.statusCode),
            isFreeBet: !!couponData?.BetDetails?.FreeBetDetails,
            selections: numberOfSelections,
            category: leagueType,
          });
          return false;
        }

        // Bet is placed successfully
        if (betResponseStatus === 1) {
          const isFreeBet = !!couponData?.BetDetails?.FreeBetDetails;

          this.dataLayerService.addBetslipEventToDataLayer({
            event: BetslipActions.BetSuccess,
            product: DataLayerProduct.VirtualsScheduled,
            userId: this.accountQuery.userData?.id,
            betStake: couponData ? couponData.Stake : undefined,
            betType: couponData ? CouponType[couponData.CouponType] : undefined,
            currency: this.accountQuery.userData.currency.name,
            couponId: couponData ? insertResponse.couponCode : undefined,
            isFreeBet: isFreeBet,
            selections: numberOfSelections,
            category: leagueType,
          });

          // turboBundliga ABTest
          const abTestConfig = this.appConfig.get('abTesting');
          const turboBundligaTest = abTestConfig?.activeTests?.find(test => test.id === 'turboBundliga');

          if (turboBundligaTest) {
            // track bet data through the 'Gtm Bet Success' VWO event
            this.abTestService
              .track(turboBundligaTest.experimentId, 'gtm.bet_success', {
                eventProperties: {
                  product: DataLayerProduct.VirtualsScheduled,
                  userId: this.accountQuery.userData?.id,
                  betStake: couponData ? couponData.Stake : undefined,
                  betType: couponData ? CouponType[couponData.CouponType] : undefined,
                  currency: this.accountQuery.userData.currency.name,
                  couponId: couponData ? insertResponse.couponCode : undefined,
                  isFreeBet: isFreeBet,
                  selections: numberOfSelections,
                  category: leagueType,
                },
              })
              .subscribe();
          }
          // turboBundliga ABTest

          // Update free bet vouchers data if bet was placed with freebet coupon
          if (isFreeBet) {
            this.freebetService.getUserVouchers().pipe(first(), takeUntil(this.destroy$)).subscribe();
          }
        }

        this.accountService.updateBalance();
        this.virtualsMyBetsService.addNewBet(insertResponse.couponCode);
        this.virtualsStore.updateLastDataCouponStakeValue(couponData.Stake);
        this.virtualsStore.updateBetSuccessDialogVisibility(true);

        return true;
      })
    );
  }

  private handleInsertCouponFailure(response): void {
    const message: string = this.getInsertCouponStatus(response.statusCode);

    if (response.statusCode === 16) {
      const depositCallback = () => {
        window.location.href = '/account/payments/deposit';
      };
      this.notificationService.showCustomNotification(
        message,
        'info',
        depositCallback,
        $localize`Deposit`,
        $localize`Insufficient balance`,
        undefined,
        undefined,
        false,
        'redesign'
      );
    } else this.notificationService.showErrorNotification(message, $localize`Coupon Not Posted`);
  }

  private handleErrorMessage(statusCode: number): void {
    const errorMessage = this.getUpdateCouponStatus(statusCode);
    const numberOfSelections = this.virtualsCouponQuery.couponData?.Odds?.length ?? 0;

    // Show error message according to the status code returned
    if (statusCode === 19) {
      // 19 => 'Stake under minimum amount allowed'. In that case we include the minimum stake amount
      this.dataLayerService.addBetslipEventToDataLayer({
        event: BetslipActions.BetslipError,
        product: DataLayerProduct.VirtualsScheduled,
        errorMessage: `${errorMessage} of ${this.getFormattedMinStake()}`,
        userId: this.accountQuery.userData?.id,
        selections: numberOfSelections,
      });
      this.notificationService.showErrorNotification(`${errorMessage} of ${this.getFormattedMinStake()}`, $localize`Coupon Not Posted`);
    } else if (statusCode === 21) {
      const message = $localize`Maximum win amount over the limit of ${this.getFormattedMaxWin()}`;

      this.dataLayerService.addBetslipEventToDataLayer({
        event: BetslipActions.BetslipError,
        product: DataLayerProduct.VirtualsScheduled,
        errorMessage: message,
        userId: this.accountQuery.userData?.id,
        selections: numberOfSelections,
      });

      this.notificationService.showErrorNotification(message, $localize`Coupon Not Posted`);
    } else if (statusCode === 22) {
      // 22 => 'Group stake under minimum amount allowed'. In that case we include the minimum group stake amount
      const minMessage = $localize`Minimum total stake is ${this.getFormattedTotalStake()}.`;
      this.dataLayerService.addBetslipEventToDataLayer({
        event: BetslipActions.BetslipError,
        product: DataLayerProduct.VirtualsScheduled,
        errorMessage: `${errorMessage} of ${this.getFormattedMinGroupStake()}. ${minMessage}`,
        userId: this.accountQuery.userData?.id,
        selections: numberOfSelections,
      });

      this.notificationService.showErrorNotification(
        `${errorMessage} ${this.getFormattedMinGroupStake()}. ${minMessage}`,
        $localize`Coupon Not Posted`
      );
    } else {
      this.dataLayerService.addBetslipEventToDataLayer({
        event: BetslipActions.BetslipError,
        product: DataLayerProduct.VirtualsScheduled,
        errorMessage: errorMessage,
        userId: this.accountQuery.userData?.id,
        selections: numberOfSelections,
      });
      this.notificationService.showErrorNotification(errorMessage, $localize`Coupon Not Posted`);
    }
  }

  private populateSessionVariables(): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    const apiCalls = [
      this.apiService.get<any>(APIType.VirtualsFeeds, `v1/settings/bonuses`, apiSettings),
      this.apiService.get<any>(APIType.VirtualsFeeds, `v1/settings/globalvariables`, apiSettings),
    ];
    const correctScoreOddsMatrix = this.virtualsCouponQuery.correctScoreOddsMatrix;
    if (
      !correctScoreOddsMatrix ||
      correctScoreOddsMatrix.cacheVersion !== this.appConfig.get('siteVersion') ||
      !correctScoreOddsMatrix.data
    ) {
      apiCalls.push(this.apiService.get<any>(APIType.VirtualsFeeds, `v1/settings/compatibility`, apiSettings));
    }

    return forkJoin(apiCalls).pipe(
      map(([bonusListData, globalVariablesData, correctScoreOddsMatrixData]) => {
        if (bonusListData !== undefined) {
          const bonusList: Bonus[] = bonusListData;
          this.accumulatorBonusStore.updateBonusList(bonusList);
        }

        if (globalVariablesData !== undefined) {
          const globalVariables: BetCouponGlobalVariable = globalVariablesData;
          this.virtualsCouponStore.updateGlobalVariables(globalVariables);
        }

        if (correctScoreOddsMatrixData !== undefined) {
          this.virtualsCouponStore.updateCorrectScoreOddsMatrix({
            cacheVersion: this.appConfig.get('siteVersion'),
            data: correctScoreOddsMatrixData,
          });
        }
      })
    );
  }

  private placeCouponLite(): Observable<any> {
    // Get Adjust IDs from cookies
    const apiSettings: APISettings = new APISettings({
      adjustId: this.cookieService.getCookie(this.ADJUST_ID) ?? null,
      gpsAdid: this.cookieService.getCookie(this.ADJUST_GPS_ID) ?? null,
      idfa: this.cookieService.getCookie(this.ADJUST_FRA_ID) ?? null,
    });

    // Replicated from UpdateCouponLite to match same model of payload
    const couponData = cloneDeep(this.virtualsCouponQuery.couponData);
    couponData.Odds.forEach(odd => {
      odd.ConfirmedOddValue = odd.OddValue;
    });
    this.virtualsCouponStore.updateCouponData(couponData);

    const bodyData = {
      AllowOddChanges: false,
      AllowStakeReduction: this.virtualsCouponQuery.couponSettings.allowStakeReduction,
      BetCoupon: this.virtualsCouponQuery.couponData,
      // RequestTransactionId: this.clientsideCouponService.generateTransactionId(),
      RequestTransactionId: nanoid(), // VIR-9305 - make the transaction id more unique
      TransferStakeFromAgent: this.virtualsCouponQuery.couponSettings.transferUserId === null ? false : true,
    };

    return this.apiService.post<any>(APIType.VirtualsBets, `v1/coupons`, bodyData, apiSettings).pipe(
      tap(response => {
        if (response.ResponseStatus === 1) {
          this.saveSelectionsToLocalStorage(bodyData.BetCoupon);
        }
      }),
      map(responseData => {
        let couponPosted = false;
        if (responseData.ResponseStatus === 1) {
          couponPosted = true;
          this.virtualsCouponStore.clearCouponData();
        }

        return {
          success: couponPosted,
          statusCode: responseData.ResponseStatus,
          couponId: responseData.CouponId,
          couponCode: responseData.CouponCode,
        };
      }),
      catchError(error => {
        return of({
          success: false,
          statusCode: error?.error?.ResponseStatus || 0,
        });
      })
    );
  }

  private getFormattedMinStake(): string {
    const minAllowedStake = this.virtualsCouponQuery.globalVariables.MinBetStake;
    return formatCurrency(minAllowedStake, 'en-GB', this.currencySymbol, '1.2-2');
  }

  private getFormattedMinGroupStake(): string {
    const minAllowedGroupingStake = this.virtualsCouponQuery.globalVariables.MinGroupingsBetStake;
    return formatCurrency(minAllowedGroupingStake, 'en-GB', this.currencySymbol, '1.2-2');
  }

  private getFormattedTotalStake(): string {
    const minAllowedGroupingStake = this.virtualsCouponQuery.globalVariables.MinGroupingsBetStake;
    let totalCombinations = 0;
    let totalStake = 0;

    this.virtualsCouponQuery.couponData.Groupings.forEach(group => {
      totalCombinations += group.Combinations;
    });

    totalStake = minAllowedGroupingStake * totalCombinations;

    return formatCurrency(totalStake, 'en-GB', this.currencySymbol, '1.2-2');
  }

  private getFormattedMaxWin(): string {
    let maxWin = 0;
    const couponType =
      this.virtualsCouponQuery.couponData && this.virtualsCouponQuery.couponData.CouponTypeId !== undefined
        ? this.virtualsCouponQuery.couponData.CouponTypeId
        : CouponType.Single;

    switch (couponType) {
      case CouponType.Single: {
        maxWin = this.virtualsCouponQuery.globalVariables.MaxSingleBetWin;
        break;
      }
      case CouponType.Multiple: {
        maxWin = this.virtualsCouponQuery.globalVariables.MaxMultipleBetWin;
        break;
      }
      case CouponType.System: {
        maxWin = this.virtualsCouponQuery.globalVariables.MaxCombinationBetWin;
        break;
      }
      default:
        maxWin = this.virtualsCouponQuery.globalVariables.MaxSingleBetWin;
    }

    return this.formatShortNumber(maxWin);
  }

  private formatShortNumber(num: number): string {
    let abs = Math.abs(num);
    let multiplicatorSymbol = '';

    const multiplicatorSymbols = [
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 },
    ];

    for (const symbol of multiplicatorSymbols) {
      let quotient = abs / symbol.value;
      quotient = Math.round(quotient * 10) / 10;
      if (quotient >= 1) {
        abs = quotient;
        multiplicatorSymbol = symbol.key;
        break;
      }
    }

    // TODO: this doesn't support localization, Intl.NumberFormat could be an option
    return `${this.currencySymbol}${num < 0 ? '-' : ''}${abs}${multiplicatorSymbol}`;
  }

  private saveSelectionsToLocalStorage(coupon: BetCoupon): void {
    const recentBetsStorageKey = this.appConfig.get('virtuals').scheduledLeague.recentBetsStorageKey;

    if (!recentBetsStorageKey) {
      return;
    }

    const recentSelections: RecentBets = JSON.parse(localStorage.getItem(recentBetsStorageKey)) || {};

    coupon.Odds.forEach(odd => {
      const selectionsForTournament = recentSelections[odd.TournamentId] || [];

      if (selectionsForTournament.length === 0) {
        recentSelections[odd.TournamentId] = selectionsForTournament;
      }

      selectionsForTournament.push({
        marketId: odd.MarketId,
        marketName: odd.MarketName,
        matchId: odd.MatchId,
        oddValue: odd.OddValue,
        selectionId: odd.IDSelectionType,
        selectionName: odd.SelectionName,
        oddsBoost: false,
      });
    });

    localStorage.setItem(recentBetsStorageKey, JSON.stringify(recentSelections));
  }
}
